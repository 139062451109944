import React from "react";

import Button, { AnchorProps, LinkProps as ButtonLinkProps } from "./Button";

export type LinkProps = Omit<ButtonLinkProps | AnchorProps, "to" | "href"> &
  ({ to: string } | { href: string });

/**
 * This component should be used when the click action will bring the user to a
 * new URL.
 *
 * Based on the `to` or `href` properties will render a React Router DOM link or
 * a regular HTML anchor.
 *
 */
const Link = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  return <Button ref={ref} variant={props.variant || "link"} {...props} />;
});

Link.displayName = "LinkButton";

export default Link;
