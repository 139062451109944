import { Selector, createSelector } from "@reduxjs/toolkit";

import { hasSafeRole } from "Libs/utils";
import { RootState } from "Store/configureStore";

import { FeatureFlagName } from "./types";

const selectSelf = (state: RootState) => {
  return state.featureFlags;
};

export const createFeatureFlagSelector = (
  flagName: FeatureFlagName
): Selector<RootState, boolean> => {
  return createSelector(selectSelf, state => state[flagName]);
};

export const selectFeatureOrganizationsEnabled = createFeatureFlagSelector(
  "ENABLE_ORGANIZATION"
);

export const selectFeatureCreateOrganizationEnabled = createFeatureFlagSelector(
  "ENABLE_CREATE_ORGANIZATION"
);

export const selectFeatureSourceRepositoryTooltipEnabled =
  createFeatureFlagSelector("ENABLE_SOURCE_REPOSITORY_TOOLTIP");

export const selectAddDomainSupportEnabled = createFeatureFlagSelector(
  "ENABLE_ADD_DOMAIN_SUPPORT"
);

export const selectFeatureBillingEnabled =
  createFeatureFlagSelector("ENABLE_BILLING");

export const selectFeatureUnbrandedArtEnabled = createFeatureFlagSelector(
  "ENABLE_UNBRANDED_ILLUSTRATIONS"
);

export const selectUpdateProjectThroughSubscriptionEnabled =
  createFeatureFlagSelector("ENABLE_UPDATE_PROJECT_THROUGH_SUBSCRIPTION");

export const selectFeatureProjectSetupWizardEnabled = createFeatureFlagSelector(
  "ENABLE_PROJECT_SETUP_WIZARD"
);

export const selectProjectProvisioningWizardEnabled = createFeatureFlagSelector(
  "ENABLE_PROJECT_PROVISIONING_WIZARD"
);

export const selectFeatureProjectSetupTipsEnabled = createFeatureFlagSelector(
  "ENABLE_PROJECT_SETUP_TIPS"
);

export const selectFeatureSourceOperationsEnabled = createFeatureFlagSelector(
  "ENABLE_SOURCE_OPERATION"
);

export const selectFeatureAutomatedBackupsEnabled = createFeatureFlagSelector(
  "ENABLE_AUTOMATED_BACKUPS"
);

export const selectFeatureBlackfireCardEnabled = createFeatureFlagSelector(
  "ENABLE_BLACKFIRE_CARD"
);
export const selectFeatureProfilingEnabled =
  createFeatureFlagSelector("ENABLE_PROFILING");

export const selectEnvironmentSocketEnabled = createFeatureFlagSelector(
  "ENABLE_ENVIRONMENT_SOCKET"
);

export const selectFeatureEditPlanEnabled =
  createFeatureFlagSelector("ENABLE_EDIT_PLAN");

export const selectFeatureObservabilitySandboxEnabled =
  createFeatureFlagSelector("ENABLE_OBSERVABILITY_SANDBOX");

export const selectFeatureAccountPagesEnabled = createFeatureFlagSelector(
  "ENABLE_ACCOUNT_PAGES"
);

export const selectFeatureCreateProjectEnabled = createFeatureFlagSelector(
  "ENABLE_CREATE_PROJECT"
);

export const selectFeatureChangeUsernameEmailEnabled =
  createFeatureFlagSelector("ENABLE_CHANGE_USERNAME_EMAIL");

export const selectFeatureDeploymentOverlayEnabled = createFeatureFlagSelector(
  "ENABLE_DEPLOYMENT_OVERLAY"
);

export const selectFeatureEnvironmentEmailSettingEnabled =
  createFeatureFlagSelector("ENABLE_ENVIRONMENT_EMAIL_SETTING");

export const selectEnvironmentDomainsEnabled = createFeatureFlagSelector(
  "ENABLE_ENVIRONMENT_DOMAINS"
);

export const selectExternalUrlEnabled = createFeatureFlagSelector(
  "ENABLE_EXTERNAL_URL"
);
export const selectFirstProjectIncentiveEnabled = createFeatureFlagSelector(
  "ENABLE_FIRST_PROJECT_INCENTIVE"
);
export const selectFreeTrialDialogEnabled = createFeatureFlagSelector(
  "ENABLE_FREE_TRIAL_DIALOG"
);
export const selectGreenDiscountEnabled = createFeatureFlagSelector(
  "ENABLE_GREEN_DISCOUNT"
);
export const selectHipaaComplianceEnabled = createFeatureFlagSelector(
  "ENABLE_HIPAA_COMPLIANCE"
);
export const selectReactivateTrialEnabled = createFeatureFlagSelector(
  "ENABLE_REACTIVATE_TRIAL"
);
export const selectFeatureAddProjectUserPricingAgreementEnabled =
  createFeatureFlagSelector("ENABLE_ADD_PROJECT_USER_PRICING_AGREEMENT");

export const selectFeatureObservabilitySuiteIntegrationsEnabled =
  createFeatureFlagSelector("ENABLE_OBSERVABILITY_SUITE_INTEGRATIONS");

export const selectFeatureUserManagementEnabled = createFeatureFlagSelector(
  "ENABLE_USER_MANAGEMENT"
);

export const selectFeatureConnectedAccountsRouteEnabled =
  createFeatureFlagSelector("ENABLE_CONNECTED_ACCOUNTS_ROUTE");

export const selectFeatureAccountSecurityRouteEnabled =
  createFeatureFlagSelector("ENABLE_ACCOUNT_SECURITY_ROUTE");

export const selectFeatureResetProfilePasswordEnabled =
  createFeatureFlagSelector("ENABLE_RESET_PROFILE_PASSWORD");

export const selectFeatureStaffTestModeEnabled = createFeatureFlagSelector(
  "ENABLE_STAFF_TEST_MODE"
);
export const selectFeatureServiceTreeEnabled = createFeatureFlagSelector(
  "ENABLE_SERVICE_TREE"
);
export const selectFeatureUsageBasedBillingEnabled = createFeatureFlagSelector(
  "ENABLE_USAGE_BASED_BILLING_PAGES"
);
export const selectSubscribeFlowEnabled = createFeatureFlagSelector(
  "ENABLE_SUBSCRIBE_FLOW"
);

export const selectFeatureHttpMetricsEnabled = state =>
  hasSafeRole(state.app?.me?.roles || []) ||
  createFeatureFlagSelector("ENABLE_HTTP_METRICS")(state);

export const selectFeatureVouchersEnabled =
  createFeatureFlagSelector("ENABLE_VOUCHERS");
export const selectFeatureResourceMetricsThresholdEnabled =
  createFeatureFlagSelector("ENABLE_RESOURCE_METRICS_THRESHOLD");

export const selectFeatureDisableAllProjectsEnabled = createFeatureFlagSelector(
  "DISABLE_ALL_PROJECTS"
);

export const selectFeatureShouldHideProjectOwner = createFeatureFlagSelector(
  "DISABLE_ALL_PROJECTS"
);

export const selectCustomLeftPanelEnabled = createFeatureFlagSelector(
  "ENABLE_CUSTOM_LEFT_PANEL"
);

export const selectDeploymentTargetEnabled = createFeatureFlagSelector(
  "ENABLE_LOCAL_DEPLOYMENT_TARGET"
);

export const selectTotalResouceAllocationEnabled = createFeatureFlagSelector(
  "ENABLE_TOTAL_RESOURCE_ALLOCATION_METRICS"
);

export const selectContinousProfillingSellableEnabled =
  createFeatureFlagSelector("ENABLE_CONTINUOUS_PROFILING_SELLABLE");

export const selectProjectAccessSelectEnabled = createFeatureFlagSelector(
  "ENABLE_PROJECT_ACCESS_SELECT"
);

export const selectSupportSellableEnabled = createFeatureFlagSelector(
  "ENABLE_SUPPORT_SELLABLE"
);

export const selectDisclaimerGeneralSupportEnabled = createFeatureFlagSelector(
  "ENABLE_DISCLAIMER_GENERAL_SUPPORT"
);

export const selectReducedWizardEnabled = createFeatureFlagSelector(
  "ENABLE_REDUCED_WIZARD"
);

export const selectCommandMenuEnabled = createFeatureFlagSelector(
  "ENABLE_COMMAND_MENU"
);

export const selectFeatureCarbonEmissionsDashboardEnabled =
  createFeatureFlagSelector("ENABLE_CARBON_EMISSIONS_DASHBOARD");
